<script lang="ts" setup>
const props = defineProps<{
  isMobile?: boolean
}>()
const emit = defineEmits(['close'])

const { t } = useI18n()
const router = useRouter()
const { event } = useEvent()
const cartStore = useCartStore()
const localePath = useLocalePath()
const globalStore = useGlobalStore()

const total = computed(() => cartStore.totalV2)

const hasItemWithNoPrice = computed(() => cartStore.hasItemWithNoPriceV2)

const formattedTotal = computed(() => {
  return formatPriceInParts(total.value.amount, { currency: globalStore.storeCurrency })
})

const handleCompleteOrderClick = () => {
  event('begin_checkout', getViewCartEventPayload(cartStore.cart.items, total.value))
  emit('close')

  cartStore.close()
  router.push(
    localePath({
      name: 'checkout',
    }),
  )
}
</script>

<template>
  <div class="cart-footer">
    <div v-if="props.isMobile">
      <div class="mb-4 flex-1 flex-col gap-1 text-base text-gray-400">
        <div class="flex items-baseline justify-between">
          <div class="text-sm font-semibold">
            {{ t('components.cartFooter.totalAmount') }}
            <span class="font-normal">
              {{
                t(
                  'components.cartFooter.productsCountMobile',
                  {
                    products: cartStore.size,
                  },
                  cartStore.size,
                )
              }}
            </span>
          </div>

          <div class="flex items-center justify-between gap-1 font-bold">
            <span v-if="hasItemWithNoPrice" class="text-base">
              {{ t('globals.noPrice') }}
            </span>

            <template v-else>
              <div class="text-xl">{{ formattedTotal.currency }}</div>
              <div class="text-xl">{{ formattedTotal.amount }}</div>
            </template>
          </div>
        </div>
      </div>

      <div class="flex-1">
        <RBaseButton
          color="primary"
          class="order-button m-auto max-w-[260px] rounded-full px-6 py-4 font-semibold"
          @click="handleCompleteOrderClick"
        >
          {{ t('components.cartFooter.completeOrder') }}
        </RBaseButton>
      </div>
    </div>

    <div v-else class="flex flex-col items-center justify-between gap-2">
      <div
        class="flex w-full flex-1 items-center justify-between gap-1 text-sm font-semibold text-gray-400"
      >
        <div>{{ t('components.cartFooter.totalAmount') }}</div>

        <div class="inline-flex items-baseline gap-1 text-xl font-bold">
          <div v-if="hasItemWithNoPrice">
            {{ t('globals.noPrice') }}
          </div>

          <template v-else>
            <div class="">{{ formattedTotal.currency }}</div>
            <div class="">{{ formattedTotal.amount }}</div>
          </template>
        </div>
      </div>

      <div class="w-[300px] flex-1">
        <button
          class="order-button w-full rounded-full px-6 py-4 font-semibold"
          @click="handleCompleteOrderClick"
        >
          {{ t('components.cartFooter.completeOrder') }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.cart-footer {
  .order-button {
    background-color: var(--color-primary);
    color: var(--color-text);
  }
}
</style>
